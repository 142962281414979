<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body" v-loading="isLoading">
			<div class="notice_head">
				<div class="center">
					<div class="page_links">
						当前位置：
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
							<el-breadcrumb-item>平台规则</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div class="tabs_wrap">
						<p :class="type==1?'active tab':'tab'" @click="typeChange('1')">星好货公告</p>
						<div style="display: flex;" v-if="pageList.length>0">
							<p :class="type==2?'active tab':'tab'" @click="typeChange('2',pageList[0].page_id)">{{pageList[0].title}}</p>
							<p :class="type==3?'active tab':'tab'" @click="typeChange('3',pageList[1].page_id)">{{pageList[1].title}}</p>
							<p :class="type==4?'active tab':'tab'" @click="typeChange('4',pageList[2].page_id)">{{pageList[2].title}}</p>
							<p :class="type==5?'active tab':'tab'" @click="typeChange('5',pageList[3].page_id)">{{pageList[3].title}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="notice_content">
				<div class="center">
					<div class="notice_menu" v-if="type==1">
						<p class="tit">全部公告</p>
						<ul class="menu_list auto_scrollbar" v-infinite-scroll="scrollLoad">
							<li v-for="(item,index) in newsList" :key="index">
								<p :class="item.id==article_id?'active':''" @click="articleClick(item.id)">{{item.title}}</p>
							</li>
						</ul>
					</div>
					<div class="notice_main" :class="type!=1?'single_page':''">
						<h3 class="notice_title">{{article_title}}</h3>
						<p class="notice_time">{{article_time}}</p>
						<div class="notice_con" v-html="richText"></div>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "noticeCenter",
	data() {
		return {
			isLoading:false,
			type:'1',
			article_id:"",
			newsList:[],
			richText:"",
			article_title:"",
			article_time:"",
			page_size:8,
			page:1,
			page_total:"",
			pageList:[]
		}
	},
	components:{
		myHead,myFoot
	},
	created(){
		this.$nextTick(function () {
			this.type = this.$route.query.type
			this.article_id = this.$route.query.id
			if(this.type == 1){
				this.getArticleList()
			}
			this.getPageList()
		})
	},
	methods: {
		...mapActions({
			getAnnouncementData:"index/getAnnouncementData",
			getAnnouncementDetail:"index/getAnnouncementDetail",
			getSinglePageData:"index/getSinglePageData",
			getSinglePageDetail:"index/getSinglePageDetail",
		}),
		getPageList(){//获取单页列表
			this.getSinglePageData({data:{limit:20,page:1},success:(res) => {
				if(res.code == 200){
					// console.log('获取单页列表',res)
					this.pageList = res.data.data
					if(this.type>1){
						this.getPageDetail()
					}
				}
			}})
		},
		getPageDetail(){//获取单页详情
			this.isLoading = true
			this.getSinglePageDetail({data:{page_id:this.article_id},success:(res) => {
				if(res.code == 200){
					// console.log('获取单页详情',res)
					this.richText = res.data.content
					this.article_title = res.data.title
					this.article_time = res.data.create_time
				}
				setTimeout(()=>{this.isLoading = false},300)
			}})
		},
		getArticleList(){//获取公告列表
			this.getAnnouncementData({data:{limit:this.page_size,page:this.page},success:(res) => {
				if(res.code == 200){
					// console.log('获取公告列表',res)
					let list = JSON.parse(JSON.stringify(res.data.data))
					if (this.page > 1) {
						let data = this.newsList.concat(list)
						this.newsList = data
					} else {
						this.newsList = list
						this.page_total = res.data.total
						this.getArticleDetail()
					}
					if(this.type==1 && !this.$route.query.id){
						this.article_id = this.newsList[0].id
						this.getArticleDetail()
					}
				}
			}})
		},
		getArticleDetail(){//获取公告详情
			this.isLoading = true
			this.getAnnouncementDetail({data:{id:this.article_id},success:(res) => {
				if(res.code == 200){
					// console.log('获取公告详情',res)
					this.richText = res.data.content
					this.article_title = res.data.title
					this.article_time = res.data.update_time
				}
				setTimeout(()=>{this.isLoading = false},300)
			}})
		},
		typeChange(type,id){//切换单页类型
			this.type = type
			this.article_id = id
			this.$router.push({
				path:'./notice',
				query:{
					type:type,
					id:id
				}
			})
			if(type>1){
				this.getPageDetail()
			}else{
				this.page = 1
				this.newsList = []
				this.getArticleList()
			}
		},
		articleClick(id){//左侧点击切换文章
			this.article_id = id
			this.getArticleDetail()
			this.$router.push({
				path:'./notice',
				query:{
					type:this.type,
					id:id
				}
			})
		},
		scrollLoad(){//左侧导航栏滚动加载
			let p = this.page
			let ps = this.page_size
			let total = this.page_total
			if (Math.ceil(total / ps) <= p) {
				return false;
			}
			this.page = this.page + 1
			this.getArticleList()
		},
		
	}
}
</script>
<style scoped>
.wrap{background: #fff;}
.center{display: flex;align-items: flex-start;}
.notice_head .center{flex-direction: column;}
.notice_head{width: 100%;height: 112px;border-bottom: 1px solid #eee;position: sticky;top: 98px;z-index: 99;background: #fff;}
.page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
.tabs_wrap{flex: 1;height: 0;display: flex;margin-top: 20px;}
.tabs_wrap .tab{padding: 0 25px;height: 100%;display: flex;justify-content: center;align-items: center;position: relative;color: #666666;cursor: pointer;}
.tabs_wrap .tab.active{color: var(--red);font-weight: bold;}
.tabs_wrap .tab.active::after{content: '';width: 100%;height: 3px;background: var(--red);position: absolute;left: 0;bottom: 0;z-index: 2;}
.notice_content{margin-bottom: 40px;}
.notice_menu{width: 286px;max-height: 480px;box-sizing: border-box;padding: 26px 20px;background: #f5f5f5;display: flex;flex-direction: column;position: sticky;top: 210px;margin-right: 40px;}
.notice_menu .tit{line-height: 1;font-size: 14px;font-weight: bold}
.notice_menu .menu_list{margin-top: 5px;flex: 1;height: 0;overflow-y: auto;overflow-x: hidden;}
.notice_menu .menu_list>li{padding: 14px 0;border-bottom: 1px solid #ddd;font-size: 14px;line-height: 18px;}
.notice_menu .menu_list>li:last-child{border-bottom: none;}
.notice_menu .menu_list>li>p{cursor: pointer;}
.notice_menu .menu_list>li>p.active{color: var(--red);display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.notice_menu .menu_list>li>p:hover{color: var(--red);text-decoration: underline;}
.notice_main{flex: 1;width: 0;padding: 40px 0;height: auto;}
.notice_main .notice_title{font-size: 28px;line-height: 32px;color: #333;}
.notice_main .notice_time{font-size: 14px;line-height: 1;color: #999;margin-top: 20px;}
.notice_main .notice_con{font-size: 16px !important;line-height: 30px;color: #333333;margin-top: 30px;}
.notice_main.single_page .notice_title ,.notice_main.single_page .notice_time{text-align: center;}
</style>
<style>
.notice_main .notice_con p{text-indent: 2em;}
.page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
.page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
.page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}
</style>